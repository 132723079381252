import React from 'react';
import Layout from '../components/Layout';

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(..//images/solutions/2.jpg)`}}
                    >
                        <h1>Línea de producción de poliéster reciclado</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1"></div>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}>
                                Línea de producción de poliéster reciclado
                            </h1>

                            <ul style={{color: '#000000'}}>
                                <li>Solución total desde botella de PET hasta fibra cortada de poliéster</li>
                                <li>Solución total desde botella de PET hasta fibra cortada de poliéster</li>
                                <li>Línea de producción de fibra de dos componentes</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
